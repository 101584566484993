@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&family=Roboto+Mono&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  background-image: url(./img/bg.gif);
  background-repeat: repeat;
  background-position: center;
  background-color: #010206e0;
  color: #FFF;
  font-family: 'Roboto Mono', monospace;
  
}

body::after {
  content:"";
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#010206e0;
  z-index:-1;
}

main {
  padding: 15px;
}

img {
  width: 75%;
  max-width: 500px;
  margin: 2rem 0 3rem 0;
}

h1 {
  font-size: 2.5rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5rem;
}

mark {
  display: inline;
  background-color: #e92d78;
  color: #FFF;
  font-weight: 300;
  padding: 0.2rem 1rem;
}

a {
  color: #FFF;
  padding: 0 0.5rem
}

.big-letters {
  font-family: 'Roboto Mono', monospace;
}

.bybulla {
  background-color: black;
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  color: #FFF;
}

.bybulla span {
  font-weight: 400;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.bulla-logo {
  display: inline-block;
  width: 60px;
  height: 22px;
  background-image: url(./img/bulla-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 0.8rem;
}
.votes {
  /* min-width: 320px;
  width: 90%;
  background: #000;
  border: 5px solid #e92d78; */
  padding: 25px 35px;
  border-radius: 0px;
  margin-bottom: 50px;
  /* box-shadow: 11px 11px 0px 0px #ff3217;
  -webkit-box-shadow: 11px 11px 0px 0px #ff3217;
  -moz-box-shadow: 11px 11px 0px 0px rgba(254,238,95,1); */
  text-align: center;
}

.votes-counter {
  display: block;
  font-size: 4.6rem;
  font-weight: 700;
  color: #e92d78;
  margin-bottom: 0.5rem;
}

.votes-people {
  display: block;
  font-size: 2rem;
  font-weight: 400;
}

.login-to-vote {
  margin-bottom: 5rem;
}

.login-to-vote p {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.vote {
  text-align: center;
  font-weight: 500;
  margin-bottom: 5rem;
}

.vote p {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

.vote span {
  font-weight: 600;
}

.vote label {
  display: block;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  color: #FFF;
}

.vote button {
  cursor:pointer;
  background-color: #e92d78;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 2rem;
  border: 0.3rem solid #f1b2cb;
}

.vote button:hover {
  border: 0.3rem solid #e92d78;
}

.voted {
  margin-bottom: 5rem;
}

.voted h2 {
  text-align: center;
  font-size: 1.8rem;
}

.share {
  text-align: center;
}

.share p {
  margin-bottom: 1rem;
  line-height: 2.4rem;
}

.share .react-share__ShareButton {
  padding: 0 0.5rem!important;
}

@media (min-width: 600px) {
  img {
    max-width: 70%;
  }
  h1 {
    font-size: 3.6rem;  
  }
  .bybulla span {
    font-size: 1.5rem;
  }
  .votes-counter {
    font-size: 5.6rem;
  }
  .login-to-vote p {
    font-size: 2.2rem;
  }
  .bulla-logo {
    width: 70px;
    height: 33px;
  }
  .vote p {
    font-size: 2.2rem;
  }
  .vote label {
    font-size: 1.5rem;
  }
  .vote button {
    font-size: 2rem;
  }
}